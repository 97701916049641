import React from "react";
import './Footer.css';
import { Link } from "react-router-dom";

const Footer = () => {
    return(
        <footer>
            <div className="container">
                <ul>
                    <li><Link style={{color: "white", textDecoration: 'none'}} to="./fliesenarbeiten">Fliesenarbeiten</Link></li>
                    <li><Link style={{color: "white", textDecoration: 'none'}} to="./renovierung">Renovierung</Link></li>
                    <li><Link style={{color: "white", textDecoration: 'none'}} to="./fensterundtueren">Fenster & Türen</Link></li>
                    <li><Link style={{color: "white", textDecoration: 'none'}} to="./wasserschaeden">Wasserschäden</Link></li>
                    <li><Link style={{color: "white", textDecoration: 'none'}} to="./sanitaetundheizung">Sanitär & Heizung</Link></li>
                    <li><Link style={{color: "white", textDecoration: 'none'}} to="./trockenbau">Trockenbau</Link></li>
                    <li><Link style={{color: "white", textDecoration: 'none'}} to="./tapezierenundstreichen">Tapezieren & Streichen</Link></li>
                    <li><Link style={{color: "white", textDecoration: 'none'}} to="/zaunanlage">Zaunanlage</Link></li>
                </ul>
                <p>&copy; 2024 AMM Bau. All Rights Reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;