import React from "react";
import './Trockenbau.css';

const Trockenbau = () => {
    return(
        <section className="trockenbau">
            <div className="trockenbau-intro">
                <div>
                    <p><b>Was versteht man unter Trockenbau?</b></p>
                    <p>
                        Trockenbau umfasst die Erstellung von raumbegrenzenden Konstruktionen wie Leichtbauwänden, Decken oder Böden, die ohne wasserhaltige Materialien auskommen.
                    </p>
                    <p><b>Welche Arbeiten gehören zum Trockenbau und mit welchen Kosten muss man rechnen?</b></p>
                    <p>
                        Zum Trockenbau zählen das Errichten von Trennwänden, das Abhängen von Decken, das Verkleiden von Wänden und Böden sowie das Einsetzen von 
                        Dämmmaterialien für Schall- und Wärmeschutz. Die Kosten variieren je nach Material, Umfang des Projekts und den spezifischen Anforderungen.
                    </p>
                    <p><b>Was ist günstiger – Trockenbauwände oder gemauerte Wände?</b></p>
                    <p>
                        Grundsätzlich ist Trockenbau oft kostengünstiger und flexibler als gemauerte Wände. 
                        Sie profitieren nicht nur von geringeren Materialkosten, sondern auch von einer schnelleren Bauzeit und weniger Feuchtigkeit im Gebäude. 
                        Ein wichtiger Bereich des Trockenbaus ist der Akustikbau, der den Schallschutz verbessert und die Raumakustik optimiert.
                    </p>
                </div>
                <img src= { require("../assets/images/trockenbau-intro.png")} alt="Ein Raum, bevore und nach Renovierung" width={'325px'} height={'100%'}></img>
            </div>
           <div>
                <h2>Was ist Trockenbau – und was versteht man unter Akustikbau?</h2>
                <p>
                    Der Trockenbau bietet beim Innenausbau hervorragende Möglichkeiten, Räume neu zu gestalten und Decken, 
                    Wände und Böden schalldämmend zu optimieren. Deshalb ist es sinnvoll, Trockenbauarbeiten in Renovierungs- oder Modernisierungsprojekte miteinzubeziehen. 
                    Im Gegensatz zum traditionellen Nassbau benötigt der Trockenbau keine wasserhaltigen Baustoffe wie Mörtel, Putz, Lehm oder Beton. 
                    Stattdessen werden vorgefertigte Bauelemente direkt vor Ort durch Klemmen, Schrauben oder Stecksysteme montiert. 
                    Je nach Bedarf und individuellen Wünschen können bei der Oberflächengestaltung auch feuchte Materialien eingesetzt werden.
                </p>
                <p>
                    Ein wesentlicher Vorteil von Trockenbau-Leichtbauwänden als Trennwände ist, dass sie keine aufwendigen Maurerarbeiten erfordern. 
                    Außerdem lassen sich Trockenbauwände bei Bedarf später problemlos wieder entfernen. 
                    Typischerweise setzen sich Trockenbauelemente aus drei Hauptbestandteilen zusammen:
                </p>
                <div>
                    <ul>
                        <li><b>Unterkonstruktion:</b> Diese besteht in der Regel aus Metallprofilen oder Holz und bietet die notwendige Stabilität.</li>
                        <li><b>Beplankung:</b> Häufig werden Gipskartonplatten, Gipsfaserplatten oder spezielle Akustikplatten verwendet.</li>
                        <li><b>Füllmaterial:</b> Um den gewünschten Schall- oder Wärmeschutz zu gewährleisten, wird Dämmmaterial zwischen den Platten eingebracht.</li>
                    </ul>
                </div>
                <p>
                    Ein besonderer Bereich des Trockenbaus ist der Akustikbau, der darauf abzielt, die Schallisolierung zu verbessern und die Raumakustik zu optimieren, 
                    um eine angenehme Klangumgebung zu schaffen. 
                </p>
            </div>
           
            
           <div className="akustikbau"> 
                <div>
                    <h2>Akustikbau: Ein wesentlicher Teil des Trockenbaus</h2>
                    <p>
                        Bei der Gestaltung von Innenräumen sollten Zwischenwände nicht nur die Raumaufteilung ermöglichen, sondern auch zur Schalldämmung beitragen. 
                        Eine Leichtbauwand sollte den Schall zwischen benachbarten Räumen reduzieren, eine Decke die Schallübertragung nach oben und unten mindern, 
                        und ein Fußboden sollte Trittschall effektiv dämmen. 
                    </p> 
                    <p>
                        Akustikbau geht darüber hinaus und zielt darauf ab, die Raumakustik zu optimieren. 
                        Die Einsatzmöglichkeiten reichen von der präzisen Installation von Lautsprechersystemen für Heimkinoanlagen bis zur Gestaltung beeindruckender 
                        Akustikkonzepte für Festspielhäuser oder Theater. Akustikbau basiert stets auf Trockenbautechniken und bezieht die akustischen Anforderungen 
                        der jeweiligen Architektur mit ein. Dazu werden gezielt spezielle Baustoffe und Verfahren eingesetzt, um die bestmögliche Klangqualität zu erzielen. 
                    </p>
                    <p>
                        Im Akustik- und Trockenbau müssen stets auch Aspekte wie Brandschutz, Statik und gestalterische Elemente berücksichtigt werden. 
                        Wir unterstützen Sie von der ersten Beratung bis zur Umsetzung Ihrer Trockenbauprojekte. Mit unseren erfahrenen Fachkräften im Bereich 
                        Trockenbau und Akustikbau sind wir bestens gerüstet, um Ihr Vorhaben professionell zu realisieren.
                    </p>
                </div>
                <img src= { require("../assets/images/akustikbau.png")} alt="Ein Raum, bevore und nach Renovierung" width={'350px'} height={'100%'}></img>
           </div>
           
           <div className="trockenbau-vorteile">
                <div>
                    <h2>Welche Arbeiten gehören zum Trockenbau?</h2>
                    <p>Der Trockenbau umfasst eine Vielzahl von Tätigkeiten, die sich in folgende Aufgabenbereiche gliedern:</p>
                    <ul>
                        <li><b>Planung und Materialermittlung:</b> Erstellung von Aufrissen und Verlegeplänen sowie die Berechnung des Materialbedarfs.</li>
                        <li><b>Vorbereitung und Unterkonstruktionen:</b> Vorbereitung der Untergründe und Anbringung der benötigten Unterkonstruktionen als Grundlage für die weiteren Bauarbeiten.</li>
                        <li><b>Flächenbekleidung:</b> Verkleidung von Wänden und Decken mit Trockenputz oder Wandverkleidungselementen.</li>
                        <li><b>Wandkonstruktionen:</b> Errichtung von Wänden aus Gipsbauplatten und anderen Leichtbaumaterialien.</li>
                        <li><b>Montagewände:</b> Bau von Wänden mit verschiedenen Systemen und Materialien, passend zu den Anforderungen des Projekts.</li>
                        <li><b>Montage von Bauteilen:</b> Zusammenbau und Installation von vorgefertigten Bauelementen vor Ort.</li>
                        <li><b>Deckenarbeiten:</b> Herstellung von abgehängten Decken, Deckenverkleidungen und speziellen Deckenverkofferungen zur Integration technischer Komponenten.</li>
                        <li><b>Dämmungsintegration:</b> Einbau von Dämmmaterialien für Schall-, Wärme-, Kälte- und Brandschutz.</li>
                        <li><b>Abdichtungsmaßnahmen:</b> Abdichtung der Trockenbauelemente gegen Feuchtigkeit und nichtdrückendes Wasser.</li>
                        <li><b>Sanierungsarbeiten:</b> Begutachtung und Analyse von Schäden sowie deren Ursachen im Rahmen von Sanierungsprojekten.</li>
                    </ul>
                    <p>
                        Diese Aufgabenbereiche des Trockenbaus bieten vielseitige Möglichkeiten zur flexiblen und individuellen Gestaltung von Innenräumen, 
                        angepasst an die spezifischen Anforderungen und baulichen Gegebenheiten.
                    </p>
                </div> 
                <img src= { require("../assets/images/trockenbau-vorteile.png")} alt="Ein Raum, bevore und nach Renovierung" width={'350px'} height={'100%'}></img>
            </div>
        </section>
    );
};

export default Trockenbau;