import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import Projects from './components/Projects';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import Fliesenarbeiten from './pages/Fliesenarbeiten';
import Tapezierenundstreichen from './pages/Tapezierenundstreichen';
import Renovierung from './pages/Renovierung';
import Sanitaetundheizung from './pages/Sanitaetundheizung';
import Trockenbau from './pages/Trockenbau';
import Fensterundtueren from './pages/Fensterundtueren';
import Zaunanlage from './pages/Zaunanlage';
import Wasserschaeden from './pages/Wasserschaeden';

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/fliesenarbeiten" element={<Fliesenarbeiten />} />
        <Route path="/tapezierenundstreichen" element={<Tapezierenundstreichen />} />
        <Route path="/renovierung" element={<Renovierung />} />
        <Route path="/sanitaetundheizung" element={<Sanitaetundheizung />} />
        <Route path="/trockenbau" element={<Trockenbau />} />
        <Route path="/fensterundtueren" element={<Fensterundtueren />} />
        <Route path="/zaunanlage" element={<Zaunanlage />} />
        <Route path="/wasserschaeden" element={<Wasserschaeden />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;

//https://aritagroup.de/ 