import React from "react";
import './Wasserschaeden.css';

const Wasserschaeden = () => {
    return (
        <section className="wasserschaeden">
            
            <div>
                <p>
                    <b>Eine Wasserschadensanierung schützt nicht nur die Bausubstanz, sondern bietet auch weitere wichtige Vorteile!</b> Bei einer professionellen Wasserschadensanierung 
                    sorgen wir dafür, dass sowohl die Kosten als auch alle notwendigen Schritte in enger Abstimmung mit Ihnen und Ihrem Versicherer erfolgen.
                </p>
                <p>
                    Zögern Sie nicht, uns zu kontaktieren! Mit unserer Expertise stehen wir Ihnen bei der Beseitigung von Wasserschäden zur Seite. 
                    Für uns zählt am Ende nur eines: das beste Ergebnis für Sie, denn das zeigt uns, dass wir unseren Job richtig machen!
                </p>
            </div>
            
            
            <div className="wasserschadensanierung">
                <div>
                    <h2>Wasserschadensanierung</h2>
                    <p>
                        Wir bieten unseren Kunden ein umfassendes Leistungsspektrum rund um die Sanierung von Wasserschäden. 
                        Seit 15 Jahren arbeiten wir erfolgreich mit Privatpersonen, Bauunternehmen, Hausverwaltungen und Versicherungen zusammen, 
                        um im Schadensfall schnelle und zuverlässige Hilfe zu leisten. Nach einer gründlichen Diagnose mittels modernster Technik und 
                        Feuchtigkeitsmessung an betroffenen Wänden, Decken und Böden, übernehmen wir die fachgerechte Sanierung.
                    </p>
                    <p>
                        Gemeinsam mit unserem Netzwerk an Partnerfirmen kümmern wir uns um die Entfernung beschädigter Materialien und deren Erneuerung, 
                        einschließlich Maler- und Tapezierarbeiten. Auch die Trocknung oder Entsorgung Ihrer von Wasserschäden betroffenen Möbel übernehmen 
                        wir für Sie. Nehmen Sie einfach Kontakt mit einer Bronzel Service-Filiale in Ihrer Nähe auf!
                    </p>
                </div>
                <img src= { require("../assets/images/wasserschadensanierung.png")} alt="Ein Raum, bevore und nach Renovierung" width={'400px'} height={'100%'}></img>
            </div>
            
            <div className="wasserschadenbeseitigung">
                <div>
                    <h2>Wasserschadenbeseitigung</h2>
                    <p>
                        Handeln Sie schnell, wenn Sie in einem Raum oder im Keller eine feuchte oder nasse Stelle entdecken, und nehmen Sie Kontakt mit uns auf.
                        Wir lokalisieren den Wasserschaden, beurteilen das Ausmaß und setzen dabei modernste Techniken wie Leckortung, Leckageortung und 
                        Feuchtigkeitsmessungen in den betroffenen Bauteilen ein. Stehendes Wasser wird umgehend entfernt, und Dämmschichten, Wände, 
                        Decken sowie Fußböden werden professionell getrocknet. 
                    </p>
                </div>
                <img src= { require("../assets/images/wasserschaenbeseitigung.png")} alt="Ein Raum, bevore und nach Renovierung" width={'400px'} height={'100%'}></img>
            </div>
            
            <div>
                <h2>Wasserschaden beseitigen – Erste Schritte zur Schadensbegrenzung</h2>
                <p>
                    Bei einem Wasserschaden können Sie den Schaden bereits im Vorfeld minimieren: Schalten Sie zuerst den Strom ab, 
                    um sich vor der Gefahr eines Stromschlags zu schützen und Kurzschlüsse zu vermeiden.
                </p>
                <p>
                    Drehen Sie das Wasser in den betroffenen Räumen ab oder schließen Sie den Haupthahn. 
                    Entfernen Sie stehendes Wasser und bringen Sie Möbel und andere Einrichtungsgegenstände aus der Gefahrenzone. 
                    Dokumentieren Sie den Wasserschaden sorgfältig, indem Sie alle betroffenen Stellen und Gegenstände fotografieren. 
                    Als Mieter sollten Sie umgehend Ihre Hausverwaltung informieren. Auf Wunsch übernehmen wir die Kommunikation mit Ihrem Vermieter und der Versicherung für Sie.
                </p>
            </div>
            
            
            <div className="schimmel">
                <div>
                    <h2>Schimmelbefall nach Wasserschaden? Vertrauen Sie auf die professionelle Schimmelpilzsanierung von Bronzel Service!</h2>
                    <p>
                        Schimmelpilze verbreiten ihre Sporen leicht und unbemerkt über die Luft. Daher sollten Sie die Schimmelpilzsanierung einem Profi 
                        wie Bronzel Service überlassen. Unsere TÜV-geprüften Mitarbeiter sind nach TRGS 524 speziell für diesen Bereich ausgebildet. 
                        Nach einer gründlichen Ursachenforschung entfernen wir den Schimmelpilz fachgerecht und riegeln die betroffenen Räume mit 
                        Folienwänden und Schleusensystemen unter Unterdruck ab, um eine Ausbreitung zu verhindern.
                    </p>
                    <p>
                        Unser Ziel ist es, die Bausubstanz möglichst zu schonen und den ursprünglichen Zustand wiederherzustellen. Wir übernehmen alle notwendigen 
                        Arbeiten und koordinieren den gesamten Ablauf, einschließlich der Entsorgung von schimmelbefallenen Einrichtungsgegenständen und Möbeln. 
                        Verlassen Sie sich auf unsere Expertise für eine sichere und nachhaltige Schimmelpilzsanierung!
                    </p>
                </div>
                <img src= { require("../assets/images/schimmel.png")} alt="Ein Raum, bevore und nach Renovierung" width={'250px'} height={'100%'}></img>
            </div>
        </section>
    );
};

export default Wasserschaeden;