import React from "react";
import './Services.css';

const Services = () => {
    return (
        <section className="services">
            <h1>Leistungen</h1>
            <p>welche Leistungen bitete die Firma an</p>
        </section>
    );
};

export default Services;