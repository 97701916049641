import React from "react";
import './Fliesenarbeiten.css';


const Fliesenarbeiten = () => {
    return (
        <section className="container">
            <section className="fliesenarbeiten">
                
                <div className="fliesenarbeiten-1">
                    <h1>Fliesenarbeiten</h1>
                    <h2>Ihr Profi für Fliesen- und Natursteinverlegung</h2>
                    <h4>Renovierungspläne für Ihr Bad oder ein anderes Projekt im Kopf?</h4>
                    <p>Unser Fliesenfachgeschäft ist Ihr verlässlicher Partner für alles rund um Fliesen. </p>
                    <p>Von der detaillierten Planung über die Auswahl der passenden Fliesen und Materialien bis hin zur fachgerechten Ausführung und den abschließenden 
                    Arbeiten – bei uns erhalten Sie alles aus einer Hand. Wir kümmern uns auch um die Koordination von Lieferanten und anderen Gewerken. </p>
                    <p>Als Meisterbetrieb sind wir spezialisiert auf die professionelle Verlegung von Fliesen und Natursteinen. Sauberes Arbeiten und die pünktliche 
                    Einhaltung von Terminen sind für uns selbstverständlich.</p>
                </div>
                
                <div className="fliesenarbeiten2-container">
                    <div className="fliesenarbeiten-2">
                        <h2>Kreative Fliesen- und Designberatung für Ihr Projekt</h2>
                        <p>Es gibt viele Wege nach Rom – und ebenso viele Möglichkeiten, Räume mit Fliesen oder Natursteinen zu verschönern.</p>
                        <p>Obwohl wir ein neues Unternehmen sind, bringen wir frischen Wind, Kreativität und Leidenschaft in jedes Projekt ein.</p>
                        <p>Wir nehmen uns persönlich Zeit, um Ihre individuellen Wünsche zu verstehen und Ihre Anforderungen zu erfüllen. </p>
                        <p>Von der Gestaltung der Räume bis zur Planung und Umsetzung sanitärer und elektrischer Anlagen stehen wir Ihnen zur Seite. 
                        Zudem beraten wir Sie bei der Auswahl der passenden Materialien und geben Empfehlungen für die Inneneinrichtung, um Ihr Projekt perfekt zu realisieren.</p>
                    </div>
                    <div>
                        <img src= { require("../assets/images/fliesenarbeiten-image1.png")} alt="Imgae1" width={'350px'} height={'100%'}></img>
                    </div>
                </div>

                <div className="fliesenarbeiten2-container">
                    <div className="fliesenarbeiten-3">
                        <h2>Planung – Der Schlüssel zu einem erfolgreichen Ergebnis liegt in einer detaillierten Vorbereitung</h2>
                        <p>Gemeinsam mit Ihnen entwickeln wir individuelle Lösungen für große und kleine Raumgestaltungen.</p>
                        <p>Von kreativen Grundrisskonzepten über durchdachte Sanitär- und Elektroinstallationen bis hin zur fachkundigen Beratung bei der Auswahl 
                        von Fliesen und Materialien – wir begleiten Sie umfassend. </p>
                        <p>Dabei legen wir ebenso viel Wert auf Funktionalität wie auf ansprechendes Wand- und Bodendesign. 
                        In jeder Bauphase stehen wir Ihnen professionell zur Seite und beraten Sie kompetent bei allen Fragen rund um Ihr Bad.</p>
                    </div>
                    <div>
                        <img src= { require("../assets/images/fliesenarbeiten-image2.png")} alt="Imgae2" width={'350px'} height={'100%'}></img>
                    </div>
                </div>

                <div className="fliesenarbeiten2-container">
                    <div className="fliesenarbeiten-4">
                        <h2>Verlegung von Fliesen und Naturstein mit Präzision</h2>
                        <p>Damit Sie lange Freude an Ihren Fliesen oder Natursteinen haben, ist eine fachgerechte und makellose Verlegung entscheidend. </p>
                        <p>Als Meisterbetrieb beraten wir Sie gerne, welche Fliesen sich für die verschiedenen Einsatzbereiche am besten eignen. </p>
                        <p>Wir stehen für höchste Qualität in der Verlegung und bei den eingesetzten Materialien. Besonders in der heutigen Zeit ist es wichtig, 
                        einen Fliesenleger Ihres Vertrauens an Ihrer Seite zu haben. </p>
                        <p>Fliesen bieten zahlreiche Vorteile gegenüber Teppich oder Parkett: 
                        Sie sind hygienisch, allergikerfreundlich, pflegeleicht, umweltfreundlich sowie beständig gegen Alterung und Lichteinwirkung.</p>
                    </div>
                    <div>
                        <img src= { require("../assets/images/fliesenarbeiten-image3.png")} alt="Imgae3" width={'350px'} height={'100%'}></img>
                    </div>
                </div>
                
                <div className="fliesenarbeiten-5">
                    <h2>Alles aus einer Hand</h2>
                    <p>Wir beraten Sie individuell und fachkundig und bieten Ihnen Rundum Service:</p>
                    <ul>
                        <li>Individuelle Beratung und Planung</li>
                        <li>Wir koordinieren alle Gewerke wie Installateur, Elektriker, Maler und Schreiner für Sie</li>
                        <li>Große Auswahl von Fliesen und anderen Materialien</li>
                        <li>Verschiedene Kombinations- und Verlegemöglichkeiten</li>
                        <li>Großes fachliches Fliesenverlegungs-Know-how</li>
                        <li>Professionelles Handwerk</li>
                        <li>Saubere Übergabe</li>
                        <li>Zuverlässigkeit und Termintreue</li>
                        <li>Faire Preiskalkulation</li>
                    </ul>
                </div>
            </section>
        </section>
    );
};

export default Fliesenarbeiten;