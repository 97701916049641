import React from "react";
import './Projects.css';

const Projects = () => {

    const images = [
        { src: require('../assets/images/image1.jpeg'), alt: 'Image 1' },
        { src: require('../assets/images/image2.jpeg'), alt: 'Image 2' },
        { src: require('../assets/images/image3.jpeg'), alt: 'Image 3' },
        { src: require('../assets/images/image4.jpeg'), alt: 'Image 4' },
        { src: require('../assets/images/image5.jpeg'), alt: 'Image 5' },
        { src: require('../assets/images/image6.jpeg'), alt: 'Image 6' }
      ];


    return (
        <section className="projects">
            <div className="projects-intro">
                <h1>Unsere Galerie – Einblick in unsere Bauprojekte</h1>
                <p>
                    Willkommen in unserer Galerie! Hier präsentieren wir stolz eine Auswahl unserer abgeschlossenen Bauprojekte und aktuellen Arbeiten. 
                    Als Baufirma legen wir großen Wert auf Qualität, Präzision und innovative Lösungen – und genau das spiegeln die Bilder in unserer Galerie wider. 
                    Entdecken Sie eindrucksvolle Fotos von unseren Baustellen, die die verschiedenen Phasen der Projekte zeigen, von der Planung bis zur Fertigstellung.
                </p>
                <p>
                    Unsere Galerie bietet Ihnen einen Blick hinter die Kulissen und zeigt, wie wir Herausforderungen meistern, Details perfektionieren und 
                    Visionen in die Realität umsetzen. Lassen Sie sich inspirieren und gewinnen Sie einen Eindruck von unserer Expertise und Leidenschaft für den Bau. 
                </p>
            </div>
            <div className="gallery-referenz">
                    {images.map((image, index) => (
                        <img
                        key={index}
                        className="gallery__item-referenz"
                        src={image.src}
                        alt={image.alt}
                        />
                    ))}
                </div>

        </section>
    );
};

export default Projects;