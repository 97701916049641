import React from "react";
import './Home.css';
import { Link } from "react-router-dom";
import 'react-before-after-slider-component/dist/build.css';
import ReactCompareImage from "react-compare-image";



const hiddenFliesenarbeiten = () => {
    const fHide = document.getElementById("f-hide");
    const sHide = document.getElementById("s-hide");
    const rHide = document.getElementById("r-hide");
    const tHide = document.getElementById("t-hide");
    const feHide = document.getElementById("fe-hide");
    const taHide = document.getElementById("ta-hide");

    if (fHide.style.display == "block"){
        fHide.style.display = "none"
    }else{
        fHide.style.display = "block";
    }
    sHide.style.display = "none";
    rHide.style.display = "none";
    tHide.style.display = "none";
    feHide.style.display = "none";
    taHide.style.display = "none";
    /*var element = document.getElementById('f-hide');
    element.classList.toggle('f-show');*/
};

const hiddenSanitaerundheizung = () => {
    const fHide = document.getElementById("f-hide");
    const sHide = document.getElementById("s-hide");
    const rHide = document.getElementById("r-hide");
    const tHide = document.getElementById("t-hide");
    const feHide = document.getElementById("fe-hide");
    const taHide = document.getElementById("ta-hide");

    fHide.style.display = "none";
    if (sHide.style.display == "block"){
        sHide.style.display = "none"
    }else{
        sHide.style.display = "block";
    }
    rHide.style.display = "none";
    tHide.style.display = "none";
    feHide.style.display = "none";
    taHide.style.display = "none";
    /*var element = document.getElementById('s-hide');
    element.classList.toggle('f-show');*/
};

const hiddenRenovierung = () => {
    const fHide = document.getElementById("f-hide");
    const sHide = document.getElementById("s-hide");
    const rHide = document.getElementById("r-hide");
    const tHide = document.getElementById("t-hide");
    const feHide = document.getElementById("fe-hide");
    const taHide = document.getElementById("ta-hide");

    fHide.style.display = "none";
    sHide.style.display = "none";
    if (rHide.style.display == "block"){
        rHide.style.display = "none"
    }else{
        rHide.style.display = "block";
    }
    tHide.style.display = "none";
    feHide.style.display = "none";
    taHide.style.display = "none";
    /*var element = document.getElementById('r-hide');
    element.classList.toggle('f-show');*/
};

const hiddenTrockenbau = () => {
    const fHide = document.getElementById("f-hide");
    const sHide = document.getElementById("s-hide");
    const rHide = document.getElementById("r-hide");
    const tHide = document.getElementById("t-hide");
    const feHide = document.getElementById("fe-hide");
    const taHide = document.getElementById("ta-hide");

    fHide.style.display = "none";
    sHide.style.display = "none";
    rHide.style.display = "none";
    if (tHide.style.display == "block"){
        tHide.style.display = "none"
    }else{
        tHide.style.display = "block";
    }
    feHide.style.display = "none";
    taHide.style.display = "none";
    /*var element = document.getElementById('t-hide');
    element.classList.toggle('f-show');*/
};

const hiddenFensterundtueren = () => {
    const fHide = document.getElementById("f-hide");
    const sHide = document.getElementById("s-hide");
    const rHide = document.getElementById("r-hide");
    const tHide = document.getElementById("t-hide");
    const feHide = document.getElementById("fe-hide");
    const taHide = document.getElementById("ta-hide");

    fHide.style.display = "none";
    sHide.style.display = "none";
    rHide.style.display = "none";
    tHide.style.display = "none";
    if (feHide.style.display == "block"){
        feHide.style.display = "none"
    }else{
        feHide.style.display = "block";
    }
    taHide.style.display = "none";
    /*var element = document.getElementById('fe-hide');
    element.classList.toggle('f-show');*/
};

const hiddenTapezierenundstreichen = () => {
    const fHide = document.getElementById("f-hide");
    const sHide = document.getElementById("s-hide");
    const rHide = document.getElementById("r-hide");
    const tHide = document.getElementById("t-hide");
    const feHide = document.getElementById("fe-hide");
    const taHide = document.getElementById("ta-hide");

    fHide.style.display = "none";
    sHide.style.display = "none";
    rHide.style.display = "none";
    tHide.style.display = "none";
    feHide.style.display = "none";
    if (taHide.style.display == "block"){
        taHide.style.display = "none"
    }else{
        taHide.style.display = "block";
    }
    /*var element = document.getElementById('ta-hide');
    element.classList.toggle('f-show');*/
};


const images = [
    { src: require('../assets/images/image1.jpeg'), alt: 'Image 1' },
    { src: require('../assets/images/image2.jpeg'), alt: 'Image 2' },
    { src: require('../assets/images/image3.jpeg'), alt: 'Image 3' },
    { src: require('../assets/images/image4.jpeg'), alt: 'Image 4' },
    { src: require('../assets/images/image5.jpeg'), alt: 'Image 5' },
    { src: require('../assets/images/image6.jpeg'), alt: 'Image 6' }
  ];


const Home = () => {
    return (
        <>
            <head>
                <meta name="viewport" content="width=1060px, maximum-scale=1.0" />
                <meta name="viewport" content="width=767px, maximum-scale=1.0" />
            </head>
            <section className="home-section">
            <div className="home">
                <div className="amm-bau-intro">
                    <h1>Willkommen bei AMM Bau</h1>
                    <h2>Ihr kompetenter Partner für hochwertige Renovierungs- und Modernisierungsarbeiten individuell abgestimmt und alles aus einer Hand.</h2>
                </div>
            </div>
            <div className="planung">
                <h2>Von der Konzeption bis zur schlüsselfertigen Ausführung</h2>
                <ul>
                    <li>Komplette Renovierungen von Wohnungen und Häusern</li>
                    <li>Bäder, Küchen, Wohnzimmer & alle anderen Zimmer</li>
                    <li>Von der ersten Idee bis zur Fertigstellung: <span>wir bieten Ihnen umfassende Beratung und Betreuung, um Ihre Wohnräume nach Ihren Wünschen zu gestalten</span></li>
                </ul>
            </div>
            <div className="vorteile">
                <div className="vorteile-firstdiv">
                    <ul>
                        <li>
                            <h3>Ein persönlicher Bauleiter begleitet Sie durch das gesamte Projekt</h3>
                            <p>Während des gesamten Bauprozesses, von der Planung bis zur Fertigstellung, steht Ihnen ein persönlicher Projektleiter zur Seite, 
                                der stets erreichbar ist. Er unterstützt Sie bei der Materialauswahl, berät Sie in Sachen Design und Raumplanung, koordiniert 
                                die Teams und sorgt für die Qualitätskontrolle in allen Gewerken – damit Sie sich entspannt zurücklehnen können
                            </p>
                        </li>
                        <li>
                            <h3>Alles aus einer Hand</h3>
                            <p>
                                Wir kümmern uns um alle Aspekte Ihrer Sanierung, von der Demontage bis zur Endreinigung – egal ob Böden, Fenster, 
                                Türen oder Sanitärinstallationen
                            </p>
                        </li>
                        <li>
                            <h3>Kurzfristige Besichtigungstermine</h3>
                            <p>
                                Unser Team ist täglich von 08:00 bis 19:00 Uhr für Sie da, inklusive der Möglichkeit für Besichtigungen am Samstag. 
                                Wir können Termine auch kurzfristig für Sie organisieren.
                            </p>
                        </li>
                    </ul>
                </div>
                <div className="vorteile-img">
                    <img src={require("../assets/images/vorteile-image.png")} alt="two workers doing tilework" width={'450px'} height={'100%'}></img>
                </div>
            </div>
            <div className="unsere-dienstleistungen">
                <h2 id="h2-diesnstleistung">Unsere Dienstleistungen</h2>
                <p>Um Einsätze mit unseren Kunden reibungslos durchzuführen, bieten wir höchste Fachkompetenz und Professionalität</p>
                <div className="btnsToShow">
                    <button className="btn-fliesenarbeiten" onClick={hiddenFliesenarbeiten}>Fliesenarbeiten</button>
                    <div id="f-hide">
                        <h2>Fliesenarbeiten</h2>
                        <p>
                            Unser Service beinhaltet eine individuelle Beratung und eine fachgerechte Umsetzung für alle Projektarten – von kleineren Reparaturen 
                            nach Wasserschäden bis hin zu luxuriösen Designbädern
                        </p>
                        <span className="learn-more"><Link style={{color: 'white', textDecoration: 'none'}} to="/fliesenarbeiten">Mehr Erfahren</Link></span>
                    </div>
                    <button className="btn-sanitaetundheizung" onClick={hiddenSanitaerundheizung}>Sanitär und Heizung</button>
                    <div id="s-hide">
                        <h2>Sanitär und Heizung</h2>
                        <p>
                            Wir bieten Ihnen eine zuverlässige und zügige Modernisierung oder Sanierung Ihrer Sanitäranlagen, inklusive Beratung zur 
                            Neugestaltung Ihres Badezimmers. Egal ob Neubau oder Badsanierung, für private oder gewerbliche Kunden – wir begleiten Sie 
                            von der Planung bis zur fachgerechten Umsetzung und realisieren Ihre individuellen Wünsche
                        </p>
                        <span className="learn-more"><Link style={{color: 'white', textDecoration: 'none'}} to="/sanitaerundheizung">Mehr Erfahren</Link></span>
                    </div>
                    <button className="btn-renovierung" onClick={hiddenRenovierung}>Renovierung</button>
                    <div id="r-hide">
                        <h2>Renovierung</h2>
                        <p>
                            Die Renovierung eines Hauses ist eine bedeutende Investition, und es ist verständlich, 
                            dass Sie kein Risiko eingehen möchten, indem Sie verschiedene Handwerker für einzelne Gewerke beauftragen, 
                            die das Gesamtprojekt nicht im Blick haben. Wir sorgen dafür, dass das gesamte Vorhaben stets im Fokus bleibt und 
                            dass die Bauleistungen optimal auf Ihr Budget abgestimmt sind. So garantieren wir Ihnen eine sichere und lohnende Investition
                        </p>
                        <span className="learn-more"><Link style={{color: 'white', textDecoration: 'none'}} to="/renovierung">Mehr Erfahren</Link></span>
                    </div>
                    <button className="btn-trockenbau" onClick={hiddenTrockenbau}>Trockenbau</button>
                    <div id="t-hide">
                        <h2>Trockenbau</h2>
                        <p>
                            Im Bereich Akustikbau bieten wir Schallabsorptionssysteme für verschiedene Einsatzbereiche wie Wohnräume, Schulen und Krankenhäuser an. 
                            Wir verwenden hochwertige Produkte von renommierten Herstellern wie Siniat und Knauf und errichten Trennwände in unterschiedlichen Stärken 
                            und Bauweisen, einschließlich Raum-in-Raum-Systemen. Zudem legen wir großen Wert auf Qualität bei der Installation von Brandschutzdecken, 
                            Schutztüren, Brandschutztrennwänden und beim Dachausbau, um eine optimale Isolierung und Dämmung zu gewährleisten
                        </p>
                        <span className="learn-more"><Link style={{color: 'white', textDecoration: 'none'}} to="/trockenbau">Mehr Erfahren</Link></span>
                    </div>
                    <button className="btn-fensterundtueren" onClick={hiddenFensterundtueren}>Fenster und Türen</button>
                    <div id="fe-hide">
                        <h2>Fenster und Türen</h2>
                        <p>
                            Als Ihr Fenstermonteur kümmern wir uns um die fachgerechte Montage Ihrer neuen Fenster, Türen und Rollläden, 
                            sodass Sie sich entspannt zurücklehnen können. Mit unserem Rundum-Service übernehmen wir alle Aufgaben rund um den Einbau, 
                            die sonst in Ihren Verantwortungsbereich fallen würden
                        </p>
                        <span className="learn-more"><Link style={{color: 'white', textDecoration: 'none'}} to="/fensterundtueren">Mehr Erfahren</Link></span>
                    </div>
                    <button className="btn-tapezierenundstreichen" onClick={hiddenTapezierenundstreichen}>Tapezieren und Streichen</button>
                    <div id="ta-hide">
                        <h2>Tapezieren und Streichen</h2>
                        <p>
                            Wir bieten Ihnen professionelle Tapezierarbeiten und eine beeindruckende Auswahl an Tapeten. 
                            Allein von einem Hersteller haben wir Zugriff auf mehr als 300 verschiedene Kollektionen. 
                            Der Trend zu glatten und geraden Wänden bleibt weiterhin beliebt. Ob puristisches Weiß in mattem Finish, 
                            kräftige Farben oder kreative Gestaltungstechniken – wir setzen Ihre Vorstellungen stilvoll um
                        </p>
                        <span className="learn-more"><Link style={{color: 'white', textDecoration: 'none'}} to="/tapezierenundstreichen">Mehr Erfahren</Link></span>
                    </div>
                </div>
            </div>
            <div className="referenzen">
                <h2>Galerie</h2>
                <p>
                    Wir wissen, dass jedes Bauprojekt einzigartig ist und unterschiedliche Anforderungen hat. 
                    Deshalb setzen wir auf individuelle Beratung und eine enge Zusammenarbeit mit unseren Kunden. 
                </p>
                <p>
                    Unser Ziel ist es, maßgeschneiderte Lösungen zu entwickeln, die genau auf Ihre Wünsche und Bedürfnisse abgestimmt sind. 
                    Standardlösungen gibt es bei uns nicht – wir gestalten jedes Projekt ganz nach Ihren Vorstellungen.
                </p>
                <div className="gallery">
                    {images.map((image, index) => (
                        <img
                        key={index}
                        className="gallery__item"
                        src={image.src}
                        alt={image.alt}
                        />
                    ))}
                </div>
            </div>
            <div className="before-after">
            <div>
                <h2>Before and After</h2>
                <div className="mainWrapper">
                    <div className="contentWrapper">
                        {/*<ReactCompareImage leftImage={require('../assets/images/vorRenovierung-image1.png')} rightImage={require('../assets/images/nachRenovierung-image1.png')} />*/}
                        {/*<ReactCompareImage leftImage={require('../assets/images/vorfliesenarbeiten.png')} rightImage={require('../assets/images/nachfliesenarbeiten.png')} />*/}
                        <ReactCompareImage leftImage={require('../assets/images/voWwasserschaden.png')} rightImage={require('../assets/images/nachWasserschaden.png')} />
                    </div>
                </div>
            </div>
            </div>
            </section>
        </>
    );
};

export default Home;