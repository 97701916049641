import React from "react";
import './Renovierung.css';

const Renovierung = () => {
    return(
        <section className="renovierung">
            <div className="intro-renovierung-container">
                <div className="intro-renovierung">
                <p>Sie möchten frischen Wind in Ihr Zuhause bringen? Ob neue Tapeten, frisch gestrichene Wände oder ein neuer Bodenbelag – 
                bei uns sind Sie genau richtig. </p>
                <p>Wir bieten Ihnen alle Renovierungsarbeiten aus einer Hand – zuverlässig und termingerecht! Egal ob Wohnzimmer, 
                Küche, Badezimmer oder andere Räume, wir verwandeln Ihre Räume in Wohlfühloasen mit einer erstklassigen Wohnatmosphäre – für Eigenheimbesitzer, 
                Wohnungseigentümer, Mieter und Hausverwaltungen.</p>
                <p>Sie fragen sich, was eine Renovierung umfasst? Welche Kosten entstehen? </p>
                <p>Und ob es Fördermöglichkeiten gibt? Auf dieser Seite finden Sie erste Antworten. </p>
                <p>In einem kostenfreien Beratungsgespräch besprechen wir anschließend alle weiteren Details.</p>
                </div>
                <div>
                    <img src= { require("../assets/images/renovierung-intro.png")} alt="Ein Raum, der dringend renoviert werden muss" width={'500px'} height={'70%'}></img>
                </div>
            </div>

            <div className="def-renovierung-container">
                <div className="container-wrapper">
                    <div className="def-renovierung">
                        <h2>Eine großartige Möglichkeit zur Neugestaltung: Die Renovierung</h2>
                        <p>Renovieren bedeutet, Schönheitsreparaturen durchzuführen. </p>
                        <p>Im Gegensatz zur Sanierung, die primär der Beseitigung von Baumängeln und der Abwendung weiterer Schäden dient, konzentriert sich 
                        die Renovierung auf die optische Aufwertung. Bestehende Bauteile, die im Laufe der Jahre leichte Abnutzungserscheinungen zeigen, 
                        werden dabei wieder „aufgefrischt“. Dabei muss das Erscheinungsbild nach der Renovierung nicht exakt dem ursprünglichen Zustand entsprechen. </p>
                        <p>Renovierungen bieten immer auch die wunderbare Möglichkeit, Räume ganz nach den eigenen Vorstellungen und Bedürfnissen neu zu gestalten. </p>
                        <p>Verleihen Sie Ihrem Zuhause eine frische, moderne Optik – sei es durch den Klassiker unter den Renovierungsarbeiten, das Tapezieren und Streichen 
                        der Innenwände, oder durch das Erneuern der Bodenbeläge. Sie können dabei denselben Bodenbelag in einem neuen Design wählen oder einen 
                        ganz neuen Stil ausprobieren, etwa vom Teppichboden zu Laminat wechseln oder umgekehrt.</p>

                        <p>Fehlt Ihnen noch die passende Inspiration für die Neugestaltung Ihrer Wohnung oder Ihres Hauses? </p>
                        <p>In unserer Beratung liefern wir Ihnen gerne kreative Ideen und Inspirationen. Auf Wunsch machen wir uns bei Ihnen vor Ort ein genaues Bild des Renovierungsbedarfs. </p>
                        <p>Auf Basis dieser Bestandsaufnahme erstellen wir ein maßgeschneidertes Angebot, das perfekt auf Ihre Wünsche und Bedürfnisse abgestimmt ist.</p>
                    </div>
                    <img id="renovierung-img" src= { require("../assets/images/renovierung.png")} alt="Ein Raum, bevore und nach Renovierung" width={'500px'} height={'100%'}></img>
                    <div className="massnahmen-renovierung">
                        <h2>Was sind Maßnahmen einer Renovierung?</h2>
                        <p>Während eine Sanierung die Bewohnbarkeit einer Immobilie sicherstellt und eine Modernisierung den Wohnwert erhöht,
                        liegt der Fokus bei der Renovierung auf der optischen Aufwertung, um das Wohngefühl zu verbessern. </p>
                        <p>Renovierungen konzentrieren sich hauptsächlich auf Schönheitsreparaturen, die das Erscheinungsbild eines Raumes verschönern. </p>
                        <p>Obwohl Sanierungsmaßnahmen Teil einer Renovierung sein können, sind sie nicht zwingend erforderlich und sollten im Einzelfall geprüft werden. </p>
                        <p>Im Vergleich zu Sanierungs- oder Modernisierungsarbeiten sind Renovierungsarbeiten weniger tiefgreifend und können, je nach handwerklichem Geschick 
                        und Mut, teilweise selbst durchgeführt werden. </p>
                        <p>Dennoch empfehlen wir, besonders bei umfangreicheren Projekten, diese Arbeiten von Fachleuten ausführen zu lassen, um ein optimales Ergebnis zu erzielen.</p>
                    </div>
                </div>
                
                
            </div>

            <div className="tatsachen-renovierung">
                <h2>Was gehört alles zu einer Renovierung?</h2>
                <p>Sämtliche Baumaßnahmen, die eine nachhaltige Energieeinsparung fördern (energetische Renovierungen), 
                umfassen zum Beispiel die Installation von Wärmedämmungen an Fassaden, die Dämmung von Kellerdecken, obersten Geschossdecken und Dächern, 
                den Einbau neuer Fenster mit Wärmeschutzverglasung, die Anschaffung von Solarkollektoren zur Wassererwärmung und eines neuen Heizkessels 
                mit höherem Wirkungsgrad sowie die Installation von Energiesparlampen. </p>
                <p>Diese Maßnahmen tragen zur Reduzierung des Verbrauchs nicht erneuerbarer Primärenergie und zum nachhaltigen Klimaschutz bei. 
                Ein weiteres Beispiel ist der Umstieg von einer Öl- oder Gasheizung auf eine umweltfreundlichere Holzpellet-Heizung.</p>

                <p> Zu den Maßnahmen, die den Wasserverbrauch nachhaltig senken, gehören der Einbau von Warm- und Kaltwasserzählern sowie die Montage wassersparender 
                WC-Drückergarnituren. Auch Regenwassergewinnungsanlagen zur Einsparung von Brauchwasser zählen zu den attraktiven Renovierungsmaßnahmen.</p>

                <p>Zudem gibt es bauliche Maßnahmen, die den Gebrauchswert eines Hauses oder einer Wohnung nachhaltig steigern. </p>
                <p>Hier sind einige Beispiele, wie Sie durch gezielte Renovierungen den Wohnkomfort und die Funktionalität Ihrer Immobilie verbessern können.</p>
                <ul>
                    <li>Anbau einer Terrasse oder eines Balkons</li>
                    <li>Einbau eines Aufzugs</li>
                    <li>Einbau einer Klimaanlage</li>
                    <li>Einbruchschutz-Maßnahmen wie der Einbau von Sicherheitstüren und Sicherheitsfenstern</li>
                    <li>Optimierung der Belichtungsverhältnisse mittels größerer Fenster</li>
                    <li>Optimierung der Belüftungsverhältnisse mittels Einbau einer Lüftungsanlage zur kontrollierten Be- und Entlüftung</li>
                    <li>Optimierung des Schallschutzes mittels Schallschutzfenstern</li>
                    <li>Gestaltung eines modernen Bades</li>
                </ul>
                    <p>
                        Maßnahmen zur langfristigen Verbesserung der allgemeinen Wohnverhältnisse umfassen alle baulichen Eingriffe, 
                        die außerhalb der eigentlichen Immobilie stattfinden. Dazu zählen beispielsweise die Errichtung von Gemeinschaftseinrichtungen wie Kinderspielplätzen, 
                        die Schaffung von Auto-Stellplätzen, oder der Anschluss der Immobilie an moderne Kommunikationsnetze wie Glasfaser oder Kabel-TV.
                    </p>

                    <p>
                        Auch gesetzlich vorgeschriebene bauliche Maßnahmen fallen in diese Kategorie: 
                        So kann der Vermieter laut Energieeinsparverordnung verpflichtet sein, veraltete Heizsysteme wie Nachtspeicherheizungen zu ersetzen oder 
                        die Dämmung der obersten Geschossdecke durchzuführen. Ebenso greifen hier die Vorschriften der Landesbauordnungen, die unter anderem 
                        die Installation von Rauchwarnmeldern in Wohnräumen vorschreiben.
                    </p>

                    <p>
                        Darüber hinaus zählen bauliche Maßnahmen zur Schaffung zusätzlichen Wohnraums zu diesen Verbesserungen. 
                        Hierzu gehören Projekte, die bestehenden Wohnraum erweitern oder neuen erschließen, wie etwa der Ausbau eines Dachgeschosses, 
                        um zusätzliche Wohneinheiten zu schaffen.
                    </p>
            </div>
        </section>
    );
};

export default Renovierung;