import React from "react";
import './Header.css';
import { Link } from "react-router-dom";


const Header = () => {


    return (
        <header>
            <div className="navbar-contact">
                <ul>
                    <li>E-Mail: info@amm-bau.de</li>
                    <li>Tel: 0176 45810167</li>
                </ul>
                
            </div>
            <div class="navbar">
                <a className="link-a"><Link style={{textDecoration: 'none'}} to="/">Home</Link></a>
                <a id="aboutUsInNavbar" className="link-a"><Link style={{textDecoration: 'none'}} to="/aboutUs"><p>Über uns</p></Link></a>
                <div class="dropdown">
                    <a class="dropbtn" id="leistung-a" href="#">Leistungen 
                    <i class="fa fa-caret-down"></i>
                    </a>
                    <div class="dropdown-content">
                        <a><Link style={{textDecoration: 'none', color:"black"}} to="/fliesenarbeiten">Fliesenarbeiten</Link></a>
                        <a><Link style={{textDecoration: 'none'}} to="/fensterundtueren">Fenster und Türen</Link></a>
                        <a><Link style={{textDecoration: 'none'}} to="/renovierung">Renovierung</Link></a>
                        <a><Link style={{textDecoration: 'none'}} to="/sanitaetundheizung">Sanität und Heizung</Link></a>
                        <a><Link style={{textDecoration: 'none'}} to="/tapezierenundstreichen">Tapezieren und Streichen</Link></a>
                        <a><Link style={{textDecoration: 'none'}} to="/trockenbau">Trockenbau</Link></a>
                        <a><Link style={{textDecoration: 'none'}} to="/wasserschaeden">Wasserschäden</Link></a>
                        <a><Link style={{textDecoration: 'none'}} to="/zaunanlage">Zaunanlage</Link></a>
                    </div>
                </div> 
                <a className="link-a"><Link style={{textDecoration: 'none'}} to="/projects">Galerie</Link></a>
                <a className="link-a"><Link style={{textDecoration: 'none'}} to="/contactUs">Kontakt</Link></a>
            </div>
        </header>
    );
};

export default Header;