import React from "react";
import './Tapezierenundstreichen.css';

const Tapezierenundstreichen = () => {
    return (
        <section className="tapezierenundstreichen">
            <div>
                <p>
                    Wenn Ihre Wohnung, Ihr Haus oder Ihre Geschäftsräume in neuem Glanz erstrahlen sollen, ist auch die Neugestaltung der Wände ein wichtiger Schritt. 
                    Ob es ums Streichen, Malern oder Tapezieren geht – wir kümmern uns darum. Auch Büros und öffentlich zugängliche Räume benötigen regelmäßig eine Auffrischung, 
                    um weiterhin einladend, freundlich oder repräsentativ zu wirken und das Unternehmensimage durch ein einzigartiges Innendesign zu unterstreichen.
                </p>
                <p>
                    Egal, ob Sie bereits eine konkrete Idee für Ihre neue Wandgestaltung haben oder sich zunächst beraten lassen möchten: 
                    AMM Bau ist Ihr kompetenter Partner für alle Wandarbeiten. Wir bieten Ihnen eine umfassende Beratung, erstellen Farbkonzepte und übernehmen das Streichen, 
                    Malern und Tapezieren Ihrer Räume. Kontaktieren Sie uns jetzt und fordern Sie ein individuelles Angebot an!
                </p>
            </div>
            <img src= { require("../assets/images/tapezierenundstreichen.png")} alt="Ein Raum, bevore und nach Renovierung" width={'400px'} height={'100%'}></img>
            <div className="tapezieren-streichen">
                <div>
                    <h2>Streicharbeiten für Ihre Räume</h2>
                    <p>
                        Die passende Wandgestaltung für jedes Zimmer zu finden, kann eine echte Herausforderung sein. 
                        Welche Farben und Designs eignen sich am besten für Schlafzimmer, Kinderzimmer oder Küche? 
                        Als Profis für Maler- und Streicharbeiten haben wir die Erfahrung und das Know-how, um Sie optimal zu beraten und Ihre Räume nach Ihren Wünschen zu gestalten.
                    </p>
                    <p>
                        Besonders im Wohnzimmer, dem Herzstück Ihres Zuhauses, wo die Familie zusammenkommt und Gäste empfangen werden, 
                        spielt die Wahl der richtigen Wandfarbe eine entscheidende Rolle. Auf großen Flächen wirken Farben oft ganz anders und kommen besonders gut zur Geltung. 
                        Da kann die Entscheidung schwerfallen – und eine perfekte Umsetzung ist ebenso wichtig. Überlassen Sie daher nichts dem Zufall und 
                        setzen Sie auf die Expertise von AMM Bau. Wir sorgen dafür, dass Ihre Wohnräume in neuem Glanz erstrahlen!
                    </p>
                    <p>
                        Natürlich stehen wir Ihnen auch für Büro- und andere Räumlichkeiten zur Verfügung, um eine frische und ansprechende Atmosphäre zu schaffen.
                    </p>
                </div>
                
                <div>
                    <h2>Farbgestaltung und Tapezierarbeiten</h2>
                    <p>
                        Möchten Sie frischen Wind in Ihre eigenen vier Wände bringen? Haben Sie sich an der bisherigen Gestaltung sattgesehen, 
                        wünschen sich eine Veränderung oder träumen von einem neuen Design? Ganz gleich, ob Sie nur eine einzelne Wand farbig gestalten, 
                        mehrere Wände oder gleich ganze Räume streichen lassen möchten – wir setzen Ihre Ideen in die Tat um!
                    </p>
                    <p>
                        Wenn Sie die komplette Wohnung neu streichen wollen, sind Sie bei uns ebenfalls genau richtig. Wir erstellen individuelle Farbkonzepte 
                        für jeden Raum und kümmern uns um die fachgerechte Umsetzung.
                    </p>
                    <p>
                        Besonders anspruchsvoll wird es, wenn ein ganzes Haus gestrichen werden soll. Statt sich selbst den Stress zu machen, 
                        überlassen Sie diese Aufgabe den Profis: AMM Bau führt Streich- und Malerarbeiten an größeren Objekten schnell und zuverlässig durch. 
                        Verlassen Sie sich auf uns für eine reibungslose und professionelle Veränderung Ihrer Räume!
                    </p>
                </div>
            </div>
        
            <div className="holz-treppen">
                <div>
                    <div>
                        <h2>Holzarbeiten: Streichen von Treppen, Fenstern und Decken</h2>
                        <p>
                            Nicht nur Wände benötigen ab und zu einen neuen Anstrich – auch Decken, Treppen und Fenster profitieren von einer regelmäßigen Auffrischung. 
                            Oftmals bestehen diese Elemente aus Holz, einem beliebten Material in vielen Häusern. 
                            Doch beim Streichen von Holzoberflächen sind spezielle Vorkenntnisse erforderlich. 
                            Das Holz muss zunächst vorbereitet und geschliffen, anschließend grundiert, zwischengeschliffen und schließlich mit einem Endanstrich versehen werden. 
                            Diese einzelnen Schritte beherrschen wir ebenso wie den Umgang mit verschiedenen Holzarten.
                        </p>
                        <p>
                            Möchten Sie beispielsweise Ihre Fenster streichen? Mit uns geht das schnell und unkompliziert. 
                            Auch Ihre Holztreppe bringen wir fachgerecht in Schuss und beraten Sie gern bei der Auswahl der passenden Farbe. 
                            Dabei berücksichtigen wir auch die Lichtverhältnisse, um sowohl ein attraktives Design zu erzielen als auch die Sicherheit durch gut 
                            sichtbare Stufen zu gewährleisten. Bei Decken spielen Lichtverhältnisse ebenfalls eine große Rolle; besonders in kleinen Räumen können 
                            dunkle Holzdecken schwer und drückend wirken. Wenn Sie Ihre Holzdecke streichen und den Raum freundlicher gestalten möchten, sind wir 
                            der richtige Ansprechpartner für Sie!
                        </p>
                    </div>
                    
                    <div>
                        <h2>Fassaden- und Treppenhausgestaltung</h2>
                        <p>
                            Neben zahlreichen Verschönerungen im Innenbereich übernehmen wir auch professionelle Malerarbeiten an Fassaden. 
                            Was für Laien eine zeitaufwendige und fehleranfällige Aufgabe sein kann, erledigen wir effizient und in gewohnt hoher Qualität. 
                            Wir unterstützen Sie bei der Entwicklung eines Farbkonzepts für Ihre Fassade und beraten Sie zu geeigneten Schutzanstrichen, 
                            die Ihre Außenwände dauerhaft vor Witterungseinflüssen schützen. Denn beim Streichen der Fassade kommt es nicht nur auf die optische Gestaltung an, 
                            sondern auch auf die Langlebigkeit und Schutzfunktion des Anstrichs.
                        </p>
                        <p>
                            Möchten Sie Ihr Treppenhaus neu streichen lassen? Auch das gehört zu unserem Leistungsspektrum. Kontaktieren Sie uns gerne, um Ihr Projekt in die Tat umzusetzen!
                        </p>
                    </div>
                </div>
                <img src= { require("../assets/images/holz-treppen-fenster-streichen.png")} alt="Ein Raum, bevore und nach Renovierung" width={'500px'} height={'100%'}></img>
            </div>
            
            <div>
                <h2>Spachteln</h2>
                <p>
                    Beim Spachteln im Trockenbau oder bei Rigips-Wänden gibt es wichtige Aspekte zu beachten. Entscheidend sind sowohl das richtige Werkzeug als 
                    auch eine fachgerechte Technik. Vor dem Spachteln müssen die Wände sorgfältig vorbereitet und gereinigt werden. Abhängig von der Beschaffenheit der Wand, 
                    etwa bei Fugen oder sichtbaren Schraubenköpfen, sind spezifische Techniken notwendig. Nach der Grundverspachtelung sorgt das Nachspachteln für ein perfektes 
                    Finish und einen nahtlosen Übergang. Oftmals sind zusätzliches Glätten und Schleifen erforderlich, um eine ebene Oberfläche zu erzielen.
                </p>
                <p>
                    Besondere Sorgfalt ist auch beim Spachteln von Decken gefragt, um ein gleichmäßiges Ergebnis ohne Risse oder Unebenheiten zu erreichen. 
                    Besonders häufig ist das Betondecke-Spachteln, aber auch andere Materialien profitieren von dieser Technik, um Makel zu beseitigen. 
                    So lassen sich beim Holzspachteln ebenfalls Löcher und beschädigte Stellen erfolgreich ausbessern.
                </p>
            </div>
            <img src= { require("../assets/images/spachtel.png")} alt="Ein Raum, bevore und nach Renovierung" width={'700px'} height={'100%'}></img>
            <div>
                <h2>Spachteltechniken</h2>
                <p>
                    Es gibt eine Vielzahl an Spachteltechniken für Wände, da nicht immer eine absolut glatte Oberfläche gewünscht ist. 
                    Oft sind dekorative Unebenheiten genau das, was den Charme einer Raumgestaltung ausmacht. Mit den passenden Spachteltechniken lassen sich verschiedene Optiken 
                    erzielen, wie zum Beispiel der Naturstein-Look, eine Marmor-Optik oder eine bewusst rustikal wirkende Oberfläche durch unregelmäßig aufgetragene Spachtelmasse.
                </p>
                <p>
                    Allerdings können diese Techniken sehr anspruchsvoll sein, besonders für Ungeübte. Neben der Vorbereitung des Untergrunds in mehreren Schritten 
                    erfordert auch die eigentliche Dekor-Spachtelung viel Sorgfalt und Erfahrung. Abhängig vom gewünschten Ergebnis können zusätzliches Schleifen, 
                    Polieren oder Wachsen notwendig sein, um den perfekten Look zu erzielen. Eine professionelle Umsetzung ist daher oft der Schlüssel zu einem 
                    beeindruckenden Endergebnis.
                </p>
            </div>
            
            <div>
                <h2>Tapezieren</h2>
                <p>
                    Wünschen Sie sich einen Tapetenwechsel? Dann ist AMM Bau Ihr zuverlässiger Partner für Maler- und Tapezierarbeiten. 
                    Egal, in welcher Phase des Entscheidungsprozesses Sie sich befinden, wir unterstützen Sie gern. Wenn Sie Ihre Wunschtapete bereits ausgewählt haben, 
                    übernehmen wir das Tapezieren Ihrer Räume schnell und unkompliziert – lehnen Sie sich zurück und freuen Sie sich auf Ihren neuen Look!
                </p>
                <p>
                    Falls Sie noch unschlüssig sind, stehen wir Ihnen mit einer ausführlichen Beratung zur Seite und präsentieren Ihnen verschiedene
                     Gestaltungsmöglichkeiten und Inspirationen. Lassen Sie uns gemeinsam Ihre Wohnträume verwirklichen!
                </p>
            </div>
        </section>
    );
};

export default Tapezierenundstreichen;