import React from "react";
import './AboutUs.css';

const AboutUs = () => {
    return (
        <section className="aboutUs">
            <h1>Fundiertes Wissen, Engagement und moderne Lösungen – Ihr Baupartner für frische Perspektiven</h1>
            <p>
                Als junges Bauunternehmen stehen wir Ihnen mit frischen Ideen, Begeisterung und Sorgfalt zur Seite. 
                Wir entwickeln maßgeschneiderte Gesamtkonzepte, die genau auf Ihre Wünsche und Anforderungen abgestimmt sind – von der Basis bis zum Dach, 
                von den Böden bis zu den Decken.
            </p>

            <p>
                Ob es um eine fachgerechte Renovierung geht oder um spezifische Aufgaben wie Innenausbau, Fliesenarbeiten, Sanitär- und Heizungsinstallationen, 
                Trockenbau, den Einbau von Fenstern und Türen, Tapezieren, Streichen und vieles mehr – bei uns profitieren Sie von unserem Engagement und 
                einem stetig wachsenden Fachwissen.
            </p>

            <p>
                Durch regelmäßige Fort- und Weiterbildungen stellen wir sicher, dass wir stets den aktuellen Vorschriften entsprechen und die neuesten Standards 
                im Baugewerbe erfüllen. So garantieren wir nicht nur eine professionelle Umsetzung Ihres Bauvorhabens, sondern helfen Ihnen auch, mögliche staatliche 
                Förderungen optimal zu nutzen.
            </p>
        </section>
    );
};

export default AboutUs;